import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, DialogTitle, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './addcase.module.scss';
import 'src/style.css';
import { DialogWrapper, AddSearchStatus } from './constants';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';
import NormalSearch from './Normal';
import AdvanceSearch from './Advance';
// import AdvanceSearch from './Advance-basic';
import { toast } from 'react-toastify';
import { eDiscoveryUrl } from 'src/common/routes';
import { useLocation, useNavigate } from 'react-router';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

type HeaderSearch = {
  onClose: (status: AddSearchStatus) => void;
  open: boolean;
};

enum AlertMessageType {
  Success = 'success',
  Error = 'error'
}

type AlertMessage = { type?: AlertMessageType; message?: string };

function HeaderSearch() {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [SearchType] = useGlobalState('SearchType');
  const [ResetState, setResetState] = useState(null);
  const [CloseModal] = useGlobalState('CloseModal');
  const [languageArray] = useGlobalState('languageArray');

  const handleClickOpen = () => {
    const caseid = localStorage.getItem('pcaseId');

    if (!caseid) {
      setGlobalState(
        'modalmessage',
        toast.error('Please select a case and search again')
      );
      return false;
    }

    setResetState(false);
    setOpen(true);
  };

  const handleClose = () => {
    setGlobalState('SearchType', 1);
    setOpen(false);
    setResetState(true);
    setGlobalState('CloseModal', false);
  };

  if (CloseModal) {
    handleClose();
  }

  const RefreshDashboard = () => {
    setGlobalState('ErrorFoundGlobal', true);
    navigate(eDiscoveryUrl.CaseS3 + '/1');
  };

  return (
    <>
      {/* <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item lg={3}> */}
      <a onClick={RefreshDashboard}>
        <img
          alt="500"
          height={60}
          src="/static/images/header-logo.png"
          style={{ float: 'left' }}
          className={styles.menuItemDiv}
        />
      </a>
      {/* </Grid> */}
      {/* <Grid item lg={3} className={styles.menuItemDiv}>
          <h3 className={styles.menuItemLabel}>
            {languageArray.length > 0 && languageArray
              ? languageArray[7]
              : 'Documents'}
          </h3>
        </Grid>
        <Grid item lg={3} className={styles.menuItemDiv}>
          <h3 className={styles.menuItemLabel}>
            {languageArray.length > 0 && languageArray
              ? languageArray[7]
              : 'Documents'}
          </h3>
        </Grid> */}
      {/* </Grid> */}
      {/* <div className={styles.menuItemDiv}>
        
      </div>
      <div className={styles.menuItemDiv}>
        
      </div> */}
      {/* <Tooltip
        arrow
        title="Search keywords across cases"
        className="header-btn"
      >
        <IconButton
          className={styles.searchIcon}
          type="submit"
          sx={{ p: '10px' }}
          aria-label="search"
          onClick={handleClickOpen}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip> */}
      {/* <NormalSearch openpopup={handleClickOpen} /> */}
      {/* <DialogWrapper
        open={open}
        keepMounted
        maxWidth="xl"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        disableScrollLock={true}
      >
        <DialogTitle className={styles.dialogTitle}>
          <b>Advanced Search </b>
          <IconButton className={styles.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
      {/* {SearchType == 1 ?  ( <NormalSearch closepopup = {handleClose} /> ) : ('')} */}
      {/* {SearchType == 2 ?  (  */}
      {/* <AdvanceSearch closepopup={handleClose} ResetState={ResetState} /> */}
      {/* ) : ('')} */}
      {/* </DialogWrapper> */}
    </>
  );
}

export default HeaderSearch;
