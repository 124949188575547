import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';
import styles from '../header.module.scss';
import InfoIcon from '@mui/icons-material/Info';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RuleIcon from '@mui/icons-material/Rule';
import { eDiscoveryUrl } from 'src/common/routes';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

const user = {
  email: localStorage.getItem('email'),
  avatar: '/static/images/avatars/6.jpg',
  jobtitle: 'admin'
};

function HeaderUserbox() {
  let navigate = useNavigate();

  const [languageArray] = useGlobalState('languageArray');
  const [subMenu, setsubMenu] = useState(false);

  const Logoutuser = () => {
    const rememberCheck = localStorage.getItem('rememberCheck');
    const rememberuser = localStorage.getItem('rememberuser');
    const selectedLanguage = localStorage.getItem('language');
    const languageType = localStorage.getItem('languageType');
    localStorage.clear();
    localStorage.setItem('language', selectedLanguage);
    localStorage.setItem('languageType', languageType);
    navigate('/login');
    setGlobalState('loggedin', false);
    localStorage.setItem('rememberCheck', rememberCheck);
    localStorage.setItem('rememberuser', rememberuser);
  };

  const OpenRules = () => {
    navigate(eDiscoveryUrl.Rules);
    setOpen(false);
    setsubMenu(false);
  };

  const handleClick = () => {
    if (subMenu) {
      setsubMenu(false);
    } else if (!subMenu) {
      setsubMenu(true);
    }
  };

  const email = localStorage.getItem('email');
  const name = email.split('@');

  const user = {
    email: email,
    name: name[0],
    avatar: '/static/images/avatars/7.png',
    jobtitle: 'admin'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setsubMenu(false);
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          alt={user.name}
          src={user.avatar}
          style={{ borderRadius: '100%' }}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1" className={styles.username}>
              {user.name}
            </UserBoxLabel>
            <UserBoxDescription variant="body2" className="position">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableScrollLock={true}
      >
        <Divider sx={{ mb: 0 }} />
        <List
          sx={{ p: 1, minWidth: { sm: 40, md: 260 } }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {/* <ListItem button={true} onClick={Aboutus} className="user-item">
            
            <ListItemText primary={'About us'} />
          </ListItem> */}
          {/* <ListItem
            button={true}
            onClick={handleClick}
            className="user-item"
            style={{ paddingBottom: '5px' }}
          > */}
          {/* <ListItemButton onClick={handleClick} className="user-item"> */}
          {/* <ListItemIcon className={styles.SubmenuIcons}>
              <InfoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Inbox" /> */}
          {/* <InfoIcon fontSize="small" style={{ marginRight: '2px' }} />
          <ListItemText primary={'About us'} /> */}
          {/* {subMenu ? (
              <ExpandLess className={styles.ExpandHover} />
            ) : (
              <ExpandMore className={styles.ExpandHover} />
            )}
          </ListItem> */}
          {/* <Collapse
            in={subMenu}
            timeout="auto"
            unmountOnExit
            className={styles.subOptions}
          >
            <List
              component="div"
              disablePadding
              className={styles.SubmenuIcons}
            >
              <ListItemButton sx={{ pl: 4 }} onClick={OpenRules}>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <RuleIcon />
                </ListItemIcon>
                <ListItemText primary="Rules" />
              </ListItemButton>
            </List>
          </Collapse> */}
          <ListItem button={true} onClick={Logoutuser} className="user-item">
            <LockOpenTwoToneIcon
              fontSize="small"
              style={{ marginRight: '2px' }}
            />
            <ListItemText
              primary={
                languageArray.length > 0 && languageArray
                  ? languageArray[91]
                  : 'Log out'
              }
            />
          </ListItem>
        </List>
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderUserbox;
