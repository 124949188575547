import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'src/style.css';

import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
// import { Amplify, Auth, Storage } from 'aws-amplify';

// Amplify.configure({
//   Auth: {
//     identityPoolId: 'us-west-2:420feb20-4862-49e5-9075-4873e6ee6e6d', //REQUIRED - Amazon Cognito Identity Pool ID
//     region: 'us-west-2', // REQUIRED - Amazon Cognito Region
//     userPoolId: 'us-west-2_KxvKJsubY' //OPTIONAL - Amazon Cognito User Pool ID
//     // userPoolWebClientId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito Web Client ID
//   },
//   Storage: {
//     AWSS3: {
//       bucket: 'ediscovery-bucket-staging', //REQUIRED -  Amazon S3 bucket name
//       region: 'us-west-2' //OPTIONAL -  Amazon service region
//     }
//   }
// });

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ClearBrowserCacheBoundary
        auto={true}
        // fallback="Loading"
        duration={30000}
      >
        <App />
      </ClearBrowserCacheBoundary>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
// serviceWorker.register();
// serviceWorker.register({
//   onUpdate: registration => {
//     alert('New version available!  Ready to update?');
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     }
//     window.location.reload();
//   }
// });
